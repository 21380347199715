import React from 'react';
import Layout from '../components/layout';

const AuthCallbackPage = () => (
  <Layout>
    <h1>Authenticating</h1>
    <p>One moment while we redirect you...</p>
    <p>If the app doesn't open immediately, press the button below:</p>
    <button
      style={{
        display: 'block',
        padding: '10px 20px',
        'background-color': '#0071e0',
        color: '#FFFFFF',
        'border-radius': '5px',
        border: 'none',
        margin: '10px auto'
      }}
      onClick={() => {
        const hash = window.location.hash;
        const url = 'dribletapp://authcallback' + hash;
        window.open(url, '_self');
      }}
    >
      Open Driblet
    </button>
  </Layout>
);

export default AuthCallbackPage;
